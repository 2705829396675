import React from 'react'
import styled from 'styled-components'
import { Container } from '../styled/layout'
import { Link } from 'gatsby'
import { Location } from '@reach/router'

import ArrowRight from '../../assets/icons/baseline-arrow_forward-16px_black.svg'

const BreadCrumbsWrapper = styled.div`
  padding: 10px 0px;
  border-top: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
  width: 100%;
  & > ${Container} {
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
  }
  @media screen and (max-width: 500px) {
    padding: 0px 0px;
  }
`

const BreadCrumbLink = styled(Link)`
  text-transform: uppercase;
  font-size: 70%;
  font-weight: 500;
  color: #000000;
  text-decoration: none;
  padding: 10px 0px;
  flex-shrink: 0;
`

const BreadCrumbs = props => {
  return (
    <React.Fragment>
      <Location>
        {({ location }) => {
          const currentLocation = location.pathname
          const pathArray = currentLocation
            .split('/')
            .splice(1)
            .filter(item => item !== '')
          const arrayLength = pathArray.length - 1
          return (
            <BreadCrumbsWrapper>
              <Container>
                <BreadCrumbLink to="/">Start</BreadCrumbLink>{' '}
                <ArrowRight style={{ flexShrink: '0', margin: '0px 10px' }} />
                {pathArray.map((item, i) => (
                  <React.Fragment key={i}>
                    <BreadCrumbLink
                      to={i === 0 ? item : `${pathArray[i - 1]}/${item}`}
                    >
                      {item

                        .split('-')
                        .join(' ')
                        .replace(/%C3%A5/g, 'å')
                        .replace(/%C3%A4/g, 'ä')
                        .replace(/%C3%B6/g, 'ö')}
                    </BreadCrumbLink>{' '}
                    {arrayLength > i ? (
                      <ArrowRight
                        style={{ flexShrink: '0', margin: '0px 10px' }}
                      />
                    ) : null}
                  </React.Fragment>
                ))}
              </Container>
            </BreadCrumbsWrapper>
          )
        }}
      </Location>
    </React.Fragment>
  )
}

export default BreadCrumbs
