import React from 'react'
import styled from 'styled-components'
import { StaticQuery, graphql } from 'gatsby'

import Img from 'gatsby-image'
import { Link } from 'gatsby'

import posed, { PoseGroup } from 'react-pose'

const ProjectMeta = styled.div`
  text-align: center;
  transition: All 0.2s;
  padding: 30px 0px;
  bottom: 0px;
  color: #000000;

  &:hover {
    opacity: 1;
  }
  & h4 {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    text-decoration: none !important;
    transition: All 0.2s;
  }
  & h4 > span {
    color: #00000060;
    display: block;
    margin-top: 10px;
    font-weight: 400;
  }
  @media screen and (max-width: 500px) {
    & > h4 {
      font-size: 0.6rem;
      line-height: 0.3rem;
    }
  }
`

const ImageWrapper = styled.div`
  overflow: hidden;
`

const ProjectItem = styled(
  posed.div({
    exit: { opacity: 0 },
    enter: { opacity: 1 },
    flip: {
      transition: {
        type: 'tween',
      },
    },
  })
)`
  overflow: hidden;
  position: relative;
  .gatsby-image-wrapper,
  ${ImageWrapper} {
    transition: All 0.2s;
  }
  &:hover .gatsby-image-wrapper {
    transform: scale(1.2);
  }
  &:hover ${ImageWrapper} {
    transform: scale(0.9);
  }
  &:hover ${ProjectMeta} h4 {
    transform: translateY(-25px);
  }
`
const ProjectsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2vmax;
  @media screen and (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`

const Projects = ({ filter, limit }) => (
  <StaticQuery
    query={graphql`
      query allProjectsQuery {
        allAirtable(
          limit: 99
          filter: {
            table: { eq: "Uppdrag" }
            data: { Status: { eq: "Publicerad" } }
          }
          sort: { fields: [data___Genomf_rdes], order: DESC }
        ) {
          edges {
            node {
              id
              data {
                Path
                Titel
                Rubrik
                Typ
                Uppdragsgivare {
                  data {
                    Organisation
                  }
                }
                Bilder {
                  localFiles {
                    childImageSharp {
                      fluid(maxWidth: 600, cropFocus: CENTER, quality: 80) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      return (
        <ProjectsGrid style={{ marginTop: '30px' }}>
          <PoseGroup>
            {data.allAirtable.edges
              .filter(({ node: item }) => {
                if (filter) {
                  return item.data.Typ === filter
                } else {
                  return item
                }
              })

              .map(({ node: project }, index) => {
                if (limit) {
                  if (index < limit) {
                    return (
                      <ProjectItem key={project.id}>
                        <Link
                          to={project.data.Path}
                          style={{ textDecoration: 'none' }}
                        >
                          <ImageWrapper>
                            <Img
                              fluid={
                                project.data.Bilder.localFiles[0]
                                  .childImageSharp.fluid
                              }
                            />
                          </ImageWrapper>
                          <ProjectMeta>
                            <h4 style={{ marginBottom: '0px' }}>
                              {project.data.Titel}

                              <span>
                                {
                                  project.data.Uppdragsgivare[0].data
                                    .Organisation
                                }
                              </span>
                            </h4>
                          </ProjectMeta>
                        </Link>
                      </ProjectItem>
                    )
                  }
                } else {
                  return (
                    <ProjectItem key={project.id}>
                      <Link
                        to={project.data.Path}
                        style={{ textDecoration: 'none' }}
                      >
                        <ImageWrapper>
                          <Img
                            fluid={
                              project.data.Bilder.localFiles[0].childImageSharp
                                .fluid
                            }
                          />
                        </ImageWrapper>
                        <ProjectMeta>
                          <h4 style={{ marginBottom: '0px' }}>
                            {project.data.Titel}

                            <span>
                              {project.data.Uppdragsgivare[0].data.Organisation}
                            </span>
                          </h4>
                        </ProjectMeta>
                      </Link>
                    </ProjectItem>
                  )
                }
                return null
              })}
          </PoseGroup>
        </ProjectsGrid>
      )
    }}
  />
)

export default Projects
