import { Container, Section } from '../components/styled/layout'
import React, { Component } from 'react'

import BreadCrumbs from '../components/UI/BreadCrumbs'
import IconFilter from '../assets/icons/baseline-filter_list-24px.svg'
import JoinNewsletter from '../components/UI/JoinNewsletter'
import Layout from '../components/layout'
import Projects from '../components/projects/Projects'
import SEO from '../components/SEO/SEO'
import Sticky from 'react-sticky-el'
import { Title } from '../components/styled/typography'
import styled from 'styled-components'

const FilterRow = styled.div`
  background: white;
  padding: 20px 0px;
  display: flex;
  z-index: 10;
  position: relative;
`

const FilterToggle = styled.span`
  color: #00000060;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 80%;
  & > span {
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 99px;

    margin: 0px 5px;
  }
  & > span.isActive {
    color: #000000;

    font-weight: 500;
  }
`

class Uppdrag extends Component {
  state = {
    filter: '',
  }
  changeFilter = this.handleFilterChange.bind(this)

  handleFilterChange(filter) {
    this.setState({
      filter,
    })
  }
  render() {
    return (
      <React.Fragment>
        <SEO title="Uppdrag" />
        <Layout>
          <BreadCrumbs />
          <Container>
            <Section className="sticky-container">
              <Title>
                Uppdrag. <span>Ett urval av vad vi gör.</span>
              </Title>
              <Sticky style={{ zIndex: '10' }}>
                <FilterRow>
                  <FilterToggle>
                    <IconFilter style={{ marginRight: '10px' }} />

                    <span
                      style={{ marginLeft: '0px' }}
                      onClick={() => this.changeFilter('')}
                      className={
                        this.state.filter === '' ? 'isActive' : undefined
                      }
                    >
                      Alla
                    </span>
                    <span
                      onClick={() => this.changeFilter('Evenemang')}
                      className={
                        this.state.filter === 'Evenemang'
                          ? 'isActive'
                          : undefined
                      }
                    >
                      Evenemang
                    </span>
                    <span
                      onClick={() => this.changeFilter('Kommunikation')}
                      className={
                        this.state.filter === 'Kommunikation'
                          ? 'isActive'
                          : undefined
                      }
                    >
                      Kommunikation
                    </span>
                  </FilterToggle>
                </FilterRow>
              </Sticky>
              <Projects filter={this.state.filter} />
            </Section>
          </Container>
        </Layout>
      </React.Fragment>
    )
  }
}

export default Uppdrag
