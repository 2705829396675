import React from 'react'
import styled from 'styled-components'

import { Heading } from '../styled/typography'

const NewsletterWrapper = styled.div`
  max-width: 900px;
  margin: 0 auto;
  & h2 {
    text-align: center;
  }
  & #mc_embed_signup_scroll {
    display: flex;
    flex-direction: row;
    .mc-field-group,
    input#mce-EMAIL {
      width: 100%;
    }
    input#mce-EMAIL {
      height: 60px;
      padding: 0px 15px;
      border-color: #d8d8d8;
      appearance: none;
      border: 1px solid #d8d8d8;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    input#mc-embedded-subscribe {
      width: 150px;
      height: 60px;
      border: 0px;
      background: black;
      color: white;
      cursor: pointer;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
    @media screen and (max-width: 500px) {
      & {
        flex-direction: column;
      }
      & h2 {
        text-align: left;
      }
      input#mc-embedded-subscribe {
        width: 100%;
        margin-top: 10px;
        border-radius: 4px;
      }
      input#mce-EMAIL {
        border-radius: 4px;
      }
    }
  }
`

const JoinNewsletter = () => {
  return (
    <NewsletterWrapper>
      <Heading>
        Missa inget från Creandia. <span>Gå med i vårt nyhetsbrev.</span>
      </Heading>
      <div id="mc_embed_signup">
        <form
          action="https://creandia.us17.list-manage.com/subscribe/post?u=10051fcf6cff8b7f773c1ce98&amp;id=3e0f7a1dbe"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          className="validate"
          target="_blank"
          noValidate
        >
          <div id="mc_embed_signup_scroll">
            <div className="mc-field-group">
              <input
                type="email"
                name="EMAIL"
                className="required email"
                id="mce-EMAIL"
                placeholder="Din epost"
              />
            </div>
            <div id="mce-responses" className="clear">
              <div
                className="response"
                id="mce-error-response"
                style={{ display: 'none' }}
              />
              <div
                className="response"
                id="mce-success-response"
                style={{ display: 'none' }}
              />
            </div>
            <div
              style={{ position: 'absolute', left: '-5000px' }}
              aria-hidden="true"
            >
              <input
                type="text"
                name="b_10051fcf6cff8b7f773c1ce98_3e0f7a1dbe"
                tabIndex="-1"
              />
            </div>
            <div className="clear">
              <input
                type="submit"
                value="Prenumerera"
                name="subscribe"
                id="mc-embedded-subscribe"
                className="button"
              />
            </div>
          </div>
        </form>
      </div>
    </NewsletterWrapper>
  )
}

export default JoinNewsletter
